/* eslint-disable no-nested-ternary */
import React from 'react';

import Icon from '+containers/Dashboard/Shared/Icons';
import ToolTip from '+shared/Tooltip';
import { TTransactionDetails, useTransactionStatusStateType } from '+types';
import { capitalize, capitalizeRemovedash, cardStatus, formatAmount, isAllowed, payinMethodMapping, switchStatus } from '+utils';

// eslint-disable-next-line import/no-cycle
import { getBankImageByName } from '../data';

// eslint-disable-next-line import/prefer-default-export
export const payInDetails = ({
  data,
  transactionType,
  updateTransactionStatusModalState,
  state,
  userAccess
}: {
  data: TTransactionDetails;
  transactionType: string;
  updateTransactionStatusModalState: (a: boolean, b: any) => void;
  state: useTransactionStatusStateType;
  userAccess: { [key: string]: boolean };
}) => {
  const {
    message,
    amount_charged: amountCharged,
    payment_source_type: paymentSourceType,
    amount_collected: amountCollected,
    currency,
    source,
    payment,
    status,
    processor_reference: processorReference,
    metadata,
    payment_source,
    status_reason,
    merchant_email
  } = data || {};

  const extraCardStatus = cardStatus[status as keyof typeof cardStatus];

  let renderStatus = (
    <>{capitalize(status === 'requires_auth' ? 'Pending (Requires Authorization)' : capitalizeRemovedash(status) || 'Not Available')}</>
  );

  if (extraCardStatus) {
    renderStatus = (
      <>
        {extraCardStatus}
        <span
          style={{
            background: '#6474FF21',
            color: '#6474FF',
            marginLeft: 5,
            padding: '2px 4px',
            borderRadius: 8,
            fontSize: 12
          }}
        >
          {status.includes('auth') ? 'AUTH' : 'CAPTURE'}
        </span>
      </>
    );
  }

  return {
    ...(!['chargebacks', 'refunds'].includes(transactionType)
      ? {
          status: (
            <>
              <span
                className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
                style={{ margin: '0 7px 5px 0' }}
              />
              {renderStatus}

              {['processing', 'expired'].includes(status) &&
                ['mobile_money', 'bank_account'].includes(data?.payment_destination_type || data?.payment_source_type) &&
                !state.clickedTransactionIds?.includes(processorReference) &&
                isAllowed(userAccess, ['payins_status.update']) && (
                  <button
                    aria-label="update transaction status"
                    onClick={e => {
                      e.stopPropagation();
                      updateTransactionStatusModalState(true, data);
                    }}
                    type="button"
                    className="status-update-icon"
                  >
                    <Icon name="settings" />
                  </button>
                )}
              {state.clickedTransactionIds?.includes(processorReference) && ['processing', 'expired'].includes(status) && (
                <ToolTip
                  type=""
                  message={
                    <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
                  }
                  centered
                >
                  <span className="rotate">
                    <Icon name="loading" />
                  </span>
                </ToolTip>
              )}
            </>
          )
        }
      : {}),
    breakdown: {
      ...(['expired', 'failed'].includes(status) && (status_reason || message) && !['refunds'].includes(transactionType)
        ? { [`reason_for_${status === 'expired' ? 'expiry' : 'failure'}`]: status_reason || message }
        : {}),
      // ...(status === 'success' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {}),
      ...(!['chargebacks', 'refunds'].includes(transactionType)
        ? {
            amount_paid: `${
              transactionType === 'refunds'
                ? formatAmount(payment_source?.amount || payment_source?.amount_collected)
                : paymentSourceType === 'bank_transfer'
                ? formatAmount(amountCollected)
                : formatAmount(amountCharged)
            } ${currency}`
          }
        : {}),
      ...(paymentSourceType === 'bank_transfer' ? { amount_expected: `${formatAmount(amountCharged)} ${currency}` } : {}),
      payment_method:
        paymentSourceType === 'payment_reversal'
          ? 'Payment Reversal'
          : payinMethodMapping[source?.type as keyof typeof payinMethodMapping] || 'Not Available',
      ...(['bank_transfer', 'pay_with_bank'].includes(source?.type) && {
        bank: (
          <>
            <img
              id="bank-icon"
              src={getBankImageByName(source?.details?.bank_slug)}
              alt="bank icon"
              style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
            />
            <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
              {capitalize(source?.details?.bank_name || 'No Bank Information Available')}
            </span>
          </>
        )
      }),
      ...(source?.type === 'wallet' && { wallet: null }),
      ...(source?.type === 'card' && { card: null }),
      ...(source?.type === 'mobile_money' && { mobile_money: null }),
      ...(['bank_transfer', 'pay_with_bank', 'wallet'].includes(source?.type) && {
        account_number: source?.details?.account_number || 'Not Available'
      }),
      account_name: (
        <>
          {source?.type === 'bank_transfer' && <span>{capitalize(source?.details?.account_name || 'Not Available')}</span>}
          {(['card', 'wallet', 'pay_with_bank', 'mobile_money', 'payment_reversal'].includes(source?.type) ||
            paymentSourceType === 'payment_reversal') && <span>{capitalize(payment?.customer?.name || 'Not Available')}</span>}
        </>
      ),
      email: (['chargebacks'].includes(transactionType) ? merchant_email : payment?.customer?.email) || 'Not provided',
      ...(['refunds'].includes(transactionType) && metadata?.trace_id ? { trace_id: metadata?.trace_id } : {})
      // ...(status === 'success' && transactionType === 'refunds' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {})
    }
  };
};
