import axios, { AxiosError, AxiosHeaders, AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';

import { INotificationSettings } from '+hooks/useNotifications';
import { Storage } from '+services/storage-services';
import {
  AccessDataT,
  chargebackDataT,
  CurrencyType,
  ExportActionType,
  FileFormatType,
  IPartnerFundingValues,
  IUpdateProductLimit,
  productType,
  ReportPayLoadType,
  SortingParamsType,
  updateProductConfigData,
  UpdateRefundStatusPayloadType
} from '+types';
import { history } from '+utils';
import useStore from '+zustandStore';
import { AuthSlice } from '+zustandStore/setAuth';

import APIServiceError from './error-services';

const APIBaseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;

export const redirectUri = process.env.REACT_APP_REDIRECT_URI || 'https://business-admin.koraapi.com/';
export default class APIRequest {
  private instance: AxiosInstance;

  constructor(baseURL?: string) {
    this.instance = axios.create({
      baseURL: baseURL || APIBaseURL,
      timeout: 50000,
      headers: {
        Accept: 'application/json'
      }
    });

    this.instance.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const configCopy = config;
        configCopy.headers = { ...config.headers } as AxiosHeaders;
        configCopy.paramsSerializer = (params: any) => {
          const result = qs.stringify(params, { arrayFormat: 'brackets' });
          return `${decodeURIComponent(result)}`;
        };
        return configCopy;
      },
      (error: any) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response: any) => {
        return response;
      },
      (error: AxiosError) => {
        if (error.config && error.config.headers) {
          const headers = { ...error.config.headers };
          delete headers.Authorization;
          error.config.headers = headers as AxiosHeaders;
        }

        if (!error.response) {
          return Promise.reject(
            new APIServiceError({
              status: 500,
              data: {
                message: 'Network Error, try again',
                error: 'server_error',
                data: null
              }
            })
          );
        }
        if (error?.response?.status === 401) {
          return this.logout();
        }
        if (error?.response?.status === 403) {
          if ((error?.response?.data as { message?: string })?.message === 'You are not authorized to perform this action') {
            return history.push('/dashboard/access-denied');
          }
          if (
            (error?.response?.data as { message?: string })?.message === 'admin account has been deactivated' ||
            (error?.response?.data as { message?: string })?.message === 'Admin account is not active'
          ) {
            this.logout();
            return history.push('/suspended');
          }
          if ((error?.response?.data as { message?: string })?.message === 'Invalid login credentials') {
            return history.push('/access-denied');
          }
          if ((error?.response?.data as { message?: string })?.message === 'admin account is invalid') {
            this.logout();
            return history.push('/deactivated');
          }
        }

        return Promise.reject(new APIServiceError(error?.response));
      }
    );
    this.checkAuthToken();
  }

  setToken = (token: string) => {
    if (token) {
      this.instance.defaults.headers.common.Authorization = `Bearer ${token}`;
    }
  };

  checkAuthToken = () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
  };

  clearToken() {
    delete this.instance.defaults.headers.common.Authorization;
  }

  storeUserToken = (token: string, refreshToken: string, userTokenExpiration: string | null) => {
    Storage.setItem('koraAdminUserToken', token);
    Storage.setItem('koraAdminRefreshToken', refreshToken);
    Storage.setItem('koraAdminUserTokenExpiration', userTokenExpiration);
  };

  // ******************************** //
  // ************** //
  // AUTH APIS
  // ************** //
  // ******************************** //

  logout = () => {
    useStore.setState((state: AuthSlice) => ({
      ...state,
      isLoading: false,
      userToken: undefined,
      refreshToken: undefined,
      isAuthenticated: false,
      error: false,
      isAuthorized: false,
      profile: {},
      userTokenExpiration: undefined,
      errors: {}
    }));
    useStore.persist.clearStorage();
    Storage.removeItem('koraAdminRefreshToken');
    Storage.removeItem('koraAdminUserTokenExpiration');
    Storage.removeItem('koraAdminUserProfile');
    Storage.removeItem('access');
    sessionStorage.removeItem('EXPORT_LINK');
    localStorage.clear();
    sessionStorage.clear();
    history.push('/');
  };

  authorizeOauth = async () => {
    const body = {
      auth_type: 'social_authorize',
      redirect_uri: redirectUri,
      provider: 'google'
    };

    const clientToken = Storage.clientToken();
    this.setToken(clientToken || '');

    const response = await this.instance.post('/admin/auth', body);
    const authResponse = response.data.data;
    return authResponse;
  };

  completeOauth = async (code: string) => {
    const body = {
      redirect_uri: redirectUri,
      auth_type: 'social_complete',
      provider: 'google',
      code
    };

    const response = await this.instance.post('/admin/auth', body);
    const authResponse = response.data;

    const { data } = authResponse;
    this.storeUserToken(data.access_token, data.refresh_token, data.expires_in);
    Storage.setItem('koraAdminUserProfile', {
      email: data.account?.email,
      avatar: data.account?.avatar
    });
    this.setToken(data.access_token);
    return authResponse;
  };

  refresh = async (token: string, client: { setToken: (arg0: string) => void }, invoiceClient: { setToken: (arg0: string) => void }) => {
    const body = {
      refresh_token: token,
      grant_type: 'refresh_token'
    };

    const response = await this.instance.post('/oauth/token', body);
    const authResponse = response.data;

    this.storeUserToken(authResponse.access_token, authResponse.refresh_token, authResponse.expires_in);
    this.setToken(authResponse.access_token);
    client.setToken(authResponse.access_token);
    invoiceClient.setToken(authResponse.access_token);
    return authResponse;
  };

  merchantEmail = async (data: any) => {
    const response = await this.instance.post('/v1/auth/get-started', data);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // UTIL APIS
  // ************** //
  // ******************************** //

  downloadFile = async (url: string) => {
    const response = await this.instance.get(url, {
      responseType: 'blob'
    });
    return response;
  };

  getBanks = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/misc/banks?limit=200');
    return response?.data?.data;
  };

  resolveBankInformation = async (data: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`v1/misc/banks/resolve`, data);
    return response.data.data;
  };

  getCurrencies = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/misc/currencies');
    return response?.data?.data;
  };

  getCountries = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/misc/countries');
    return response?.data?.data;
  };

  getProcessorList = async (params?: { category: string } | undefined) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/processors`, {
      params
    });
    return response.data.data;
  };

  // ******************************** //
  // ************** //
  // MERCHANT APIS
  // ************** //
  // ******************************** //

  createMerchant = async (body: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post('/admin/merchants', body);
    return response.data;
  };

  getMerchants = async (kycStatus: string, page?: number, sortingParams?: SortingParamsType, limit?: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const params: { page?: number; limit?: string; leadsClass?: string; bvnStatus?: string } = {
      ...sortingParams,
      page,
      limit
    };

    if (kycStatus) {
      if (kycStatus === 'submitted') {
        params.bvnStatus = kycStatus;
      } else {
        params.leadsClass = kycStatus;
      }
    }

    const response = await this.instance.get('/admin/merchants', { params });
    return response.data;
  };

  exportMerchants = async (
    fieldsToExport: string | string[],
    format: FileFormatType,
    sortingParams: SortingParamsType,
    live?: undefined
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const params: {
      leadsClass?: string;
      fieldsToExport: string | string[];
      format: FileFormatType;
      canGoLive?: boolean;
    } = {
      fieldsToExport,
      format,
      ...sortingParams
    };
    if (live) params.canGoLive = live;
    const response = await this.instance.get(`/admin/merchants`, {
      params,
      responseType: 'blob'
    });
    return response;
  };

  getMerchant = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/${id}`);
    return response?.data?.data;
  };

  getMerchantByKoraId = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/details/${id}`);
    return response?.data?.data;
  };

  fetchMerchantsReadyForVerification = async (page: number, sortingParams: SortingParamsType, limit: number) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/kyc/pending-verifications/merchants', {
      params: { page, limit, ...sortingParams }
    });
    return response.data;
  };

  exportMerchantsReadyForVerification = async (
    fieldsToExport: string | string[],
    format: FileFormatType,
    sortingParams: SortingParamsType
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/kyc/pending-verifications/merchants`, {
      params: {
        fieldsToExport,
        format,
        ...sortingParams
      },
      responseType: 'blob'
    });
    return response;
  };

  toggleMerchantStatus = async (id: string, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/merchants/${id}/set-status`, data);
    return response;
  };

  updateMerchantKyc = async (id: string, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/kyc/merchants/${id}/`, data);
    return response.data;
  };

  getMerchantSettlements = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settings/${id}/settlement`);
    return response.data;
  };

  updateMerchantSettlementsConfig = async (id: string, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/settings/${id}/settlement`, data);
    return response.data;
  };

  treatMerchantSettlementAccount = async (id: string, settlementAcctId: any, status: string, comment: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const data = status === 'rejected' ? { status, comment } : { status };
    const response = await this.instance.post(`/admin/merchants/${id}/settlement-accounts/${settlementAcctId}/treat`, data);
    return response.data;
  };

  getMerchantTeamMembers = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/teams/${id}`);
    return response?.data?.data;
  };

  getMerchantStatistics = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/statistics`);
    return response?.data?.data;
  };

  getMerchantKyc = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/${id}/kyc`);
    return response?.data?.data;
  };

  updateMerchantStatus = async (id: string, status: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}/status`, { status });
    return response.data;
  };

  sendMerchantFeedback = async (id: string, data: { action: string; feedback: Record<string, any> }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}/kyc/status`, data);
    return response.data;
  };

  verifyMerchant = async (id: string, data: { action: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}/kyc/status`, data);
    return response.data;
  };

  rejectBvn = async (id: string, data: { action: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}/kyc/bvn/status`, data);
    return response.data;
  };

  getMerchantKYCInfo = async (merchantId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/${merchantId}/kyc`);
    return response.data;
  };

  getMerchantPaymentPreference = async (merchantId: string, currency: CurrencyType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settings/${merchantId}/bank-transfers`);
    return response?.data?.data;
  };

  updateMerchantRisk = async (id: string, data: Record<string, any>) => {
    const token: string | null | boolean | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}/risk-levels`, data);
    return response.data;
  };

  manageSentinal = async (id: string, data: { status: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const method = (!data && 'get') || (data.status ? 'put' : 'post');
    const response = await this.instance[method](`/admin/tax-management/${id}`, data);
    return response.data;
  };

  unlockMerchant = async (merchantId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/user-management/${merchantId}?action=unlock`);
    return response?.data;
  };

  disableTwoFactor = async (userId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/totp/${userId}`);
    return response?.data;
  };

  updateMerchantDetails = async (id: string, data: { merchantEmail?: string; merchantName?: string }) => {
    const token: string | null | boolean | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}`, data);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // BALANCES APIS
  // ************** //
  // ******************************** //

  getBalances = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/${id}/balances`);
    return response.data;
  };

  getRollingReserveHistory = async (id: string, currency: string, page: number, limit: number) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/merchant/${id}/rolling-reserves`, {
      params: { page, limit, currency }
    });
    return response.data;
  };

  getBalanceHistory = async (id: string, currency: string, page: number, limit: number, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/${id}/balances/history/${currency}`, {
      params: { page, limit, ...sortingParams }
    });
    return response.data;
  };

  getMerchantProductLimit = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/${id}/product-limits`);
    return response.data;
  };

  getAllTierLevels = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/tier-levels`);
    return response.data;
  };

  updateMerchantTier = async (id: string, data: IUpdateProductLimit) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/merchants/${id}/tier-levels`, data);
    return response.data;
  };

  exportMerchantHistory = async (
    type: string,
    id: string,
    currency: string,
    format: FileFormatType,
    fieldsToExport: string | string[],
    date_from: string,
    date_to: string
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(
      `${
        type === 'balance' ? `/admin/merchants/${id}/balances/history/${currency}` : `/admin/transactions/merchant/${id}/rolling-reserves`
      }`,
      {
        params: { fieldsToExport, format, date_from, date_to },
        responseType: 'blob'
      }
    );
    return response;
  };

  // ******************************** //
  // ************** //
  // FEE APIS
  // ************** //
  // ******************************** //

  getMerchantFee = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/fees/merchant/${id}`);
    return response.data;
  };

  updateMerchantFee = async (id: string, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/fees/merchant/${id}`, data);
    return response.data;
  };

  resetAllFeeRules = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.delete(`/admin/fees/merchant/${id}`);
    return response.data;
  };

  resetFeeRules = async (id: string, feeId?: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.delete(`/admin/fees/merchant/${id}`, {
      params: { fee_id: feeId }
    });
    return response.data;
  };

  getDefaultFees = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/fees/default');
    return response.data;
  };

  updateDefaultFees = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put('/admin/fees/default', data);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // TRANSACTION APIS
  // ************** //
  // ******************************** //

  getTransactions = async (kind: string, limit: string, sortingParams: SortingParamsType, currency: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/${kind}`, {
      params: { limit, ...sortingParams, currency }
    });
    return response.data.data;
  };

  exportTransactions = async (
    kind: string,
    sortingParams: SortingParamsType,
    format: FileFormatType,
    currency: string,
    fieldsToExport: string | string[]
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/${kind}`, {
      params: {
        format,
        currency,
        ...sortingParams,
        fieldsToExport
      },
      responseType: 'blob'
    });
    return response;
  };

  exportSettlementsByTab = async (
    sortingParams: SortingParamsType,
    format: FileFormatType,
    currency: string,
    status: string | string[],
    fieldsToExport: string | string[],
    settlementReferences: string | undefined
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/settlements', {
      params: { format, currency, settlementReferences, ...sortingParams, status: [status], fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  getFileDownload = async (link: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/exports/reports/${link}`);
    return response;
  };

  getSingleTransaction = async (kind: string, ref: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/${kind}/${ref}`);

    return response.data.data;
  };

  getSingleTransactionFromWebhookServices = async (kind: string, ref: string, paymentType: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/${kind}/${ref}/${paymentType}`);
    return response.data.data;
  };

  getPayinReversals = async (ref: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/payins/${ref}/reversals`);
    return response.data.data;
  };

  getReversals = async (page: number | string, limit: number | string, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/payment-reversals`, {
      params: { ...sortingParams, page, limit }
    });
    return response.data.data;
  };

  getReversal = async (ref: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/payment-reversals/${ref}`);
    return response.data.data;
  };

  getPayoutReversals = async (page: number, limit: number, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/payout-reversals`, {
      params: { ...sortingParams, page, limit }
    });
    return response.data.data;
  };

  initiatePayoutReversal = async ({ payout_reference, reason }: { payout_reference: string; reason: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/payout-reversals/initiate`, { payout_reference, reason });
    return response.data.data;
  };

  processPayoutReversal = async ({ reference, action }: { reference: string; action: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/payout-reversals/process`, { reference, action });
    return response.data.data;
  };

  retryReversals = async (data: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/payment-reversals/retry`, data);
    return response.data.data;
  };

  resolveReversal = async (data: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/payment-reversals/resolve`, data);
    return response.data.data;
  };

  updateReversal = async (data: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/payment-reversals/update`, data);
    return response.data.data;
  };

  exportReversals = async (format: FileFormatType, fieldsToExport: string | string[], sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/payment-reversals`, {
      params: { format, ...sortingParams, fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  exportPayoutReversals = async (format: FileFormatType, fieldsToExport: string[] | string, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/payout-reversals`, {
      params: { format, ...sortingParams, fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  getBulkPayouts = async (page: string, limit: string, sortingParams: SortingParamsType, currency: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/bulk-transfers/?currency=${currency}`, {
      params: { page, limit, ...sortingParams }
    });
    return response?.data?.data;
  };

  getBulkPayoutTransactionList = async (bulkReference: string, page: number, limit: number, sortingParams: SortingParamsType) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/bulk/${bulkReference}/payouts`, {
      params: { page, limit, ...sortingParams }
    });
    return response?.data?.data;
  };

  getBulkPayoutSummary = async (bulkReference: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/bulk/${bulkReference}`);
    return response?.data?.data;
  };

  exportBulkPayouts = async (
    bulkReference: string,
    currency: string,
    sortingParams: SortingParamsType,
    format: string,
    fieldsToExport: string[]
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/bulk/${bulkReference}/payouts`, {
      params: {
        format,
        currency,
        ...sortingParams,
        'fieldsToExport[]': fieldsToExport
      },
      responseType: 'blob'
    });
    return response;
  };

  updateTransactionStatus = async (transaction_type: string, status: string, reason: string, processorReference?: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/transactions/${transaction_type}/${processorReference}`, {
      status,
      status_update_reason: reason
    });
    return response.data;
  };

  getRelatedTransactions = async (ref: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/payments/${ref}?limit=3`);
    return response.data.data;
  };

  // ******************************** //
  // VIRTUAL BANK APIS
  // ******************************** //

  getVirtualBankAccountOverview = async (_, __, params) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/overview?currency=${params?.currency}`);
    return response.data.data;
  };

  getFixedVirtualBankAccounts = async (page, limit, sortingParams) => {
    const paramsCopy = { ...sortingParams };
    delete paramsCopy.keyword;
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`admin/virtual-bank-account`, {
      params: { ...paramsCopy, page, limit }
    });
    return response.data.data;
  };

  getFixedVirtualBankAccountUpgradeRequests = async (page, limit, sortingParams) => {
    const paramsCopy = { ...sortingParams };
    delete paramsCopy.keyword;
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`admin/virtual-bank-account/upgrade-requests`, {
      params: { ...paramsCopy, page, limit }
    });
    return response.data.data;
  };

  getSingleFixedVirtualBankAccountUpgradeRequests = async id => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`admin/virtual-bank-account/upgrade-requests/${id}`);
    return response.data.data;
  };

  getFixedVirtualBankAccountUpgradeRequestsDeclines = async (korapayReference, status) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`admin/virtual-bank-account/upgrade-requests`, {
      params: { korapayReference, status }
    });
    return response.data.data;
  };

  getVirtualBankAccount = async id => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/virtual-bank-account/${id}`);
    return response.data;
  };

  manageVirtualBankAccount = async (reference, action, reason) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.post(`admin/virtual-bank-account/manage`, { reference, action, reason });
    return response.data;
  };

  manageAccountHolder = async (reference, action, reason) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.post(`admin/virtual-bank-account/account-holders/manage`, { reference, action, reason });
    return response.data;
  };

  getVirtualBankAccountTransactions = async (id, page = 1, limit = 25) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/virtual-bank-account/${id}/transactions`, {
      params: { page, limit }
    });
    return response.data.data;
  };

  getAllVirtualBankAccountTransactions = async (page, limit, sortingParams) => {
    const { dateCreated, ...params } = sortingParams;
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`admin/transactions/payins?payment_method=virtual_bank_account`, {
      params: {
        page,
        limit,
        ...params
      }
    });
    return response.data.data;
  };

  getVirtualBankAccountLimits = async (currency: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get('admin/settings/virtual-bank-account/limits', {
      params: { currency }
    });
    return response.data;
  };

  updateVirtualBankAccountLimits = async data => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.patch('admin/settings/virtual-bank-account/limits', data);
    return response.data;
  };

  upgradeVirtualBankAccount = async (upgrade_request_reference, upgrade_status, reason) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.post(`admin/virtual-bank-account/upgrade`, {
      upgrade_request_reference,
      upgrade_status,
      ...(reason && { reason })
    });
    return response.data;
  };

  getAllAccountHolders = async (page, limit, sortingParams) => {
    const { keyword, currency, ...paramsCopy } = sortingParams;

    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders`, {
      params: { page, limit, ...paramsCopy }
    });
    return response.data.data;
  };

  getAccountHolder = async accountReference => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders/${accountReference}`);
    return response.data.data;
  };

  getAllVirtualBankAccounts = async (accountReference, currency, searchTerm, created, status) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const param = { currency, searchTerm, created, status };
    const response = await this.instance.get(`/admin/virtual-bank-account?accountHolderReference=${accountReference}`, { param });
    return response.data.data;
  };

  getVbaKycInfo = async accountReference => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders/${accountReference}`);
    return response.data.data;
  };

  getAccountHolderKycInfo = async accountReference => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders/${accountReference}/kyc-details`);
    return response.data.data;
  };

  getAccountHolderEvent = async accountReference => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders/${accountReference}/events`);
    return response.data.data;
  };

  updateAccountHolderKyc = async payload => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.post(`/admin/virtual-bank-account/account-holders/manage`, payload);
    return response.data;
  };

  getAccountHolderKycHistory = async accountReference => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders/${accountReference}/kyc-history`);
    return response.data.data;
  };

  getAllAccountHolderTransactions = async accountReference => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/virtual-bank-account/account-holders/${accountReference}/transactions`);
    return response.data;
  };

  vbaTransactionReprocess = async ({ id, reason, currency }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.post(`/admin/transactions/payins/${id}/flagged`, {
      currency,
      reason
    });
    return response.data.data;
  };

  // ******************************** //
  // ************** //
  // ANALYTICS APIS
  // ************** //
  // ******************************** //

  getTransactionsOverview = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/transactions/analytics/count/status');
    return response.data.data;
  };

  getTransactionAnalytics = async (filter = null) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/transactions/analytics/count', { params: filter });
    return response.data.data;
  };

  getFeeAnalytics = async (type = null) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/fees/analytics/${type}`);
    return response.data.data;
  };

  getProcessorBalanceAnalytics = async (processor = null) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/processors/analytics/balance/${processor}`);
    return response.data.data;
  };

  getSignupAnalytics = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/analytics/merchants/count`);
    return response.data.data;
  };

  getMerchantTransactionAnalytics = async (
    merchantId: string,
    startDate: string | undefined,
    endDate: string | undefined,
    currency: string
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const param: { startDate?: string; endDate?: string } = { startDate, endDate };
    if (!startDate) delete param.startDate;
    if (!endDate) delete param.endDate;
    const response = await this.instance.get(`/admin/merchants/${merchantId}/analytics/${currency}/transactions`, {
      params: param
    });
    return response.data.data;
  };

  getMerchantPerformanceAnalytics = async (
    merchantId: string,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined,
    currency: string
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const param: { startDate?: string; endDate?: string } = { startDate, endDate };
    if (!startDate) delete param.startDate;
    if (!endDate) delete param.endDate;
    const response = await this.instance.get(`/admin/merchants/${merchantId}/analytics/${currency}/transactions/count`, {
      params: param
    });
    return response.data.data;
  };

  uploadFiles = async (file: FormData) => {
    const response = await this.instance.post(`/files?field_name=files`, file);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // AUDIT LOG APIS
  // ************** //
  // ******************************** //

  getLogs = async (page: string, limit: string, dateFrom: string, dateTo: string, userId?: string) => {
    const params: { page: string; limit: string; date_from?: string; date_to?: string } = { page, limit };
    if (dateFrom) params.date_from = dateFrom;
    if (dateTo) params.date_to = dateTo;
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const key = userId ? `?kora_id=${userId}` : '';
    const response = await this.instance.get(`/admin/audit${key}`, { params });
    return response?.data?.data;
  };

  getMerchantLogs = async (id: string, page: number, limit: number, dateFrom: string, dateTo: string) => {
    const params: { page: number; limit: number; date_from?: string; date_to?: string } = { page, limit };
    if (dateFrom) params.date_from = dateFrom;
    if (dateTo) params.date_to = dateTo;
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/audit-logs/${id}`, { params });
    return response?.data?.data;
  };

  // ******************************** //
  // ************** //
  // WALLET APIS
  // ************** //
  // ******************************** //

  getWallets = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/wallets/total`);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // SUMMARY APIS
  // ************** //
  // ******************************** //

  getSummary = async (dateFrom: any, dateTo: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    let params = {};
    if (dateFrom || dateTo) {
      params = { date_from: dateFrom, date_to: dateTo };
    }
    const response = await this.instance.get(`/admin/transactions/summary`, {
      params
    });
    return response.data;
  };

  // ******************************** //
  // ************** //
  // SETTLEMENT APIS
  // ************** //
  // ******************************** //

  getSettlementSummary = async (currency: currencyType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/settlements/summary', {
      params: { currency }
    });
    return response.data;
  };

  getSettlements = async (limit: string, status: string[], sortingParams: SortingParamsType, currency: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/settlements', {
      params: { status, limit, ...sortingParams, currency }
    });
    return response.data;
  };

  getSettlement = async (settlementId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settlements/${settlementId}`);
    return response.data;
  };

  getSingleSettlementTransactions = async (settlementId: string, page = 1, limit = 25) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settlements/${settlementId}/transactions`, { params: { page, limit } });
    return response.data;
  };

  exportSettlements = async (
    status: string[],
    fieldsToExport: string | string[],
    format: FileFormatType,
    sortingParams: SortingParamsType,
    currency: any
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settlements`, {
      params: { status, fieldsToExport, format, ...sortingParams, currency },
      responseType: 'blob'
    });
    return response;
  };

  exportSettlementTransactions = async (settlementId: string, format: FileFormatType, fieldsToExport: string | string[]) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settlements/${settlementId}/transactions`, {
      params: { format, fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  approveSettlement = async (settlementId: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/settlements/${settlementId}/approve`);
    return response.data;
  };

  processSettlement = async (settlementId: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/settlements/${settlementId}/process`);
    return response.data;
  };

  updateDefaultSettlementsConfig = async (data: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/settings/settlement`, data);
    return response.data;
  };

  getDefaultSettlementsConfig = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/settings/settlement`);
    return response.data;
  };

  resetMerchantSettlementsConfig = async (merchantId: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/settings/${merchantId}/settlement/reset`);
    return response.data;
  };

  updateFlagOfSettlementTxn = async (settlementId: any, txnRef: any, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/settlements/${settlementId}/transaction/${txnRef}/update-flag-status`, data);
    return response.data;
  };

  getAllPayouts = async (sortingParams: SortingParamsType, page: any, limit = 10) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/settlements/payouts', {
      params: { page, limit, ...sortingParams }
    });
    return response.data;
  };

  exportPayouts = async (format: FileFormatType, fieldsToExport: string[], sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/settlements/payouts', {
      params: { format, fieldsToExport, ...sortingParams },
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // ************** //
  // WEBHOOKS APIS
  // ************** //
  // ******************************** //

  getAllMerchants = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/merchants/filter-options');
    return response.data;
  };

  getWebhookNotifications = async (page: number, limit: number, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/webhooks/merchants', {
      params: { page, limit, ...sortingParams }
    });
    return response.data.data;
  };

  exportWebhookNotifications = async (sortingParams: SortingParamsType, format: FileFormatType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/webhooks/merchants', {
      params: { format, ...sortingParams },
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // ************** //
  // USERS APIS
  // ************** //
  // ******************************** //

  getAllUsers = async (page: string, limit: string, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/users', {
      params: { page, limit, ...sortingParams }
    });
    return response?.data?.data;
  };

  getUser = async (userId: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/users/${userId}`);
    return response?.data?.data;
  };

  getAllInvitedUser = async (page: string, limit: string, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const status = Array.isArray(sortingParams.status) ? sortingParams.status[0] : !sortingParams.status ? 'pending' : sortingParams.status;
    const response = await this.instance.get('/admin/invites', {
      params: { page, limit, ...sortingParams, status }
    });
    return response?.data?.data;
  };

  getInvitedUser = async (code: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/invites/${code}`);
    return response?.data?.data;
  };

  getAllRoles = async (page: number, limit: number, sortingParams?: SortingParamsType, showAll?: boolean | undefined) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/user-roles`, {
      params: { page, limit, showAll, ...sortingParams }
    });
    return response?.data?.data;
  };

  getRole = async (role: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/user-roles/${role}`);
    return response?.data?.data;
  };

  createUserRole = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/user-roles`, data);
    return response?.data?.data;
  };

  addOrUpdateUserRole = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/users/role-user`, data);
    return response?.data?.data;
  };

  getAllPermissions = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/permissions`);
    return response?.data?.data;
  };

  getUsersPermissions = async (userId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/permissions?userId=${userId}`);
    return response?.data?.data;
  };

  addOrUpdatePermissionToUser = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.put(`/admin/users/add-permission-user`, data);
    return response?.data?.data;
  };

  inviteNewUser = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/invites`, data);
    return response?.data?.data;
  };

  cancelAdminUserInvite = async (code: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/invites/${code}/cancel`);
    return response?.data?.data;
  };

  updateUserStatus = async (userId: any, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/users/${userId}/status`, data);
    return response?.data?.data;
  };

  deactivateUser = async (userId: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.delete(`/admin/users/${userId}`);
    return response?.data?.data;
  };

  acceptAdminUserInvite = async (code: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/invites/${code}/accept`);
    return response?.data?.data;
  };

  getAllRoleAssignees = async (id: string, page: string, limit: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/user-roles/${id}/users`, {
      params: { page, limit }
    });
    return response?.data?.data;
  };

  removeUserFromRole = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/users/remove-role-user`, data);
    return response?.data?.data;
  };

  addRole = async (data: { name: string; permissions: string[] }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/user-roles`, data);
    return response?.data?.data;
  };

  updateRole = async (id: string, data: { name: any; permissions: any[] | undefined }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/user-roles/${id}`, data);
    return response?.data?.data;
  };

  exportUsersPermissions = async (
    userId: string,
    format: ExportActionType['format'],
    fieldsToExport: { entity: ExportActionType['fieldsToExport'] }
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/permissions?userId=${userId}`, {
      params: {
        format,
        ...fieldsToExport
      },
      responseType: 'blob'
    });
    return response;
  };

  exportRole = async (
    roleId: string,
    format: ExportActionType['format'],
    fieldsToExport: { entity: ExportActionType['fieldsToExport'] }
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/permissions`, {
      params: {
        roleId,
        format,
        ...fieldsToExport
      },
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // ************** //
  // REFUNDS APIS
  // ************** //
  // ******************************** //

  getRefunds = async (page: any, limit: any, sortingParams: SortingParamsType, currency: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/refunds`, {
      params: { page, limit, ...sortingParams, currency }
    });
    return response.data.data;
  };

  getSingleRefund = async (ref: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/refunds/${ref}`);
    return response.data.data;
  };

  getRefundSummary = async (currency: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/refunds/reports/summary', {
      params: { currency }
    });
    return response.data;
  };

  getRefundStatus = async (reference: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/refunds/${reference}`);
    return response.data;
  };

  updateRefundStatus = async (ref: any, data: Record<string, any>) => {
    const response = await this.instance.patch(`admin/refunds/${ref}/status`, data);
    return response.data;
  };

  exportRefunds = async (sortingParams: SortingParamsType, format: FileFormatType, currency: string, fieldsToExport: string | string[]) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/refunds`, {
      params: {
        format,
        ...sortingParams,
        currency,
        'fieldsToExport[]': fieldsToExport
      },
      responseType: 'blob'
    });
    return response;
  };

  manageRefund = async (refundReference: string, data: { status: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/refunds/${refundReference}/status`, data);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // CHARGEBACKS APIS
  // ************** //
  // ******************************** //

  getChargebacks = async (page: any, limit: any, sortingParams: SortingParamsType, currency: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/chargebacks`, {
      params: { page, limit, currency, ...sortingParams }
    });
    return response.data.data;
  };

  uploadChargeback = async (data: chargebackDataT) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/chargebacks/single`, data);
    return response.data.data;
  };

  uploadChargebacks = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`/admin/chargebacks/bulk`, data);
    return response.data.data;
  };

  getSingleChargeback = async (reference: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/chargebacks/${reference}`);
    return response.data.data;
  };

  updateChargeback = async (reference: string, data: void) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/chargebacks/${reference}`, data);
    return response.data.data.data;
  };

  getChargebackSummary = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('admin/chargebacks/summary');
    return response.data.data;
  };

  exportChargebacks = async (sortingParams: SortingParamsType, format: FileFormatType, currency: string, fieldsToExport: string[]) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/chargebacks`, {
      params: { format, ...sortingParams, currency, 'fieldsToExport[]': fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  // ******************************** //
  // ************** //
  // CARD ISSUANCE APIs
  // ************** //
  // ******************************** //

  fetchStatistics = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const cardStats = await this.instance.get('/admin/cards/statistics');
    const transactionStats = await this.instance.get('/admin/transactions/card-issuance/statistics');
    const issuingMerchantCountStats = (await this.instance.get('/admin/merchants/card-issuance/count')) || { count: 0 };
    return { ...cardStats?.data?.data, ...transactionStats?.data?.data, ...issuingMerchantCountStats?.data?.data };
  };

  fetchAllVirtualCardMerchants = async (
    page: any,
    limit: any,
    sortingParams: SortingParamsType,
    toExport = false,
    format: FileFormatType
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/cards`, {
      params: { ...(toExport ? { format } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchAllIssuedCards = async (
    page: number | string,
    limit: number | string,
    sortingParams: SortingParamsType,
    toExport = false,
    format: FileFormatType,
    fieldsToExport: string | string[]
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/cards?cardCategory=customer`, {
      params: { ...(toExport ? { exportFormat: format, exportFields: fieldsToExport } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchAllReservedCards = async (
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    format: FileFormatType,
    fieldsToExport: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/cards?cardCategory=reserved`, {
      params: { ...(toExport ? { exportFormat: format, exportFields: fieldsToExport } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchMerchantWalletHistory = async (
    merchantId: string,
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    format: FileFormatType,
    fieldsToExport: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const res = await this.instance.get(`/admin/transactions/card-issuance/wallets/${merchantId}?currency=USD`, {
      params: { ...(toExport ? { format, fieldsToExport } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? res : res?.data?.data;
  };

  fetchMerchantCards = async (
    merchantId: string,
    cardCategory: string,
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    format: string,
    fieldsToExport: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const res: any = await this.instance.get(`/admin/cards`, {
      params: {
        merchantKoraId: merchantId,
        cardCategory,
        ...(toExport ? { exportFormat: format, exportFields: fieldsToExport } : { page, limit }),
        ...sortingParams
      },
      ...(toExport && { responseType: 'blob' })
    });

    return toExport ? res : res?.data?.data;
  };

  fetchMerchantTrxns = async (
    merchantId: string,
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    format: string,
    fieldsToExport: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const res = await this.instance.get(`/admin/transactions/card-issuance`, {
      params: {
        merchantKoraId: merchantId,
        ...(toExport ? { exportFormat: format, exportFields: fieldsToExport } : { page, limit }),
        ...sortingParams
      },
      ...(toExport && { responseType: 'blob' })
    });

    return toExport ? res : res?.data?.data;
  };

  fetchMerchantChargebacks = async (
    merchantId: string,
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    format: string,
    fieldsToExport: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const res = await this.instance.get(`/admin/chargebacks/card-issuance`, {
      params: {
        merchantKoraId: merchantId,
        ...(toExport ? { exportFormat: format, exportFields: fieldsToExport } : { page, limit }),
        ...sortingParams
      },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? res : res?.data?.data;
  };

  fetchAllVirtualCardTransactions = async (
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    exportFormat?: string,
    exportFields?: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/card-issuance`, {
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchBalanceFunding = async (
    page?: number,
    limit?: number,
    sortingParams?: SortingParamsType,
    toExport?: boolean,
    exportFormat?: string,
    exportFields?: string,
    id?: string
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/card-issuance/wallets/deposit-requests`, {
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit, reference: id }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  decideFundingRequest = async (reference: any, data: { reason?: string; description: string; action: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/wallets/card-issuance/deposit-requests/${reference}/update`, data);
    return response?.data?.data;
  };

  fetchVirtualCard = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/cards/${id}`);
    return response?.data?.data;
  };

  fetchVirtualCardTransactions = async (
    id: string,
    page: any,
    limit: any,
    sortingParams: any,
    toExport = false,
    exportFormat: any,
    exportFields: any
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/cards/${id}/transactions`, {
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchACardTransaction = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/card-issuance/${id}`);
    return response?.data?.data;
  };

  fetchVirtualCardEvents = async (
    id: string,
    page: any,
    limit: any,
    sortingParams: any,
    toExport = false,
    exportFormat: any,
    exportFields: any
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/cards/${id}/events`, {
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchVirtualCardWalletHistory = async (
    id: string,
    page: any,
    limit: any,
    sortingParams: any,
    toExport = false,
    format: 'csv' | 'excel' | 'xlsx'
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/transactions/merchant/${id}/card-issuance/wallet/transactions`, {
      params: { ...(toExport ? { format } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchAllIssuanceMerchants = async (
    page: number,
    limit: number,
    sortingParams: SortingParamsType,
    toExport = false,
    exportFormat?: string,
    exportFields?: string[]
  ) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get('/admin/merchants/card-issuance', {
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchIssuingMerchantDetails = async (ref: string) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/merchants/card-issuance/${ref}`);
    return response?.data?.data;
  };

  fetchAllIssuanceChargebacks = async (
    page: any,
    limit: any,
    sortingParams: SortingParamsType,
    toExport: any,
    exportFormat: any,
    exportFields: any
  ) => {
    const response = await this.instance.get(`/admin/chargebacks/card-issuance`, {
      params: { ...(toExport ? { exportFormat, exportFields } : { page, limit }), ...sortingParams },
      ...(toExport && { responseType: 'blob' })
    });
    return toExport ? response : response?.data?.data;
  };

  fetchSingleIssuanceChargeback = async (ref: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/chargebacks/card-issuance/${ref}`);
    return response.data.data;
  };

  updateIssuanceChargebackStatus = async (
    ref: string,
    payload: {
      status?: string | undefined;
      reason?: string | undefined;
      description?: string | undefined;
      evidence?: string | null | undefined;
      accepted_amount?: string | undefined;
      declined_amount?: string | undefined;
    }
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/chargebacks/card-issuance/${ref}`, payload);
    return response.data;
  };

  updateChargebackRefundStatus = async (reference: string, payload: UpdateRefundStatusPayloadType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/transactions/card-issuance/refunds/${reference}`, payload);
    return response?.data?.data;
  };

  updateVirtualCard = async (id: string, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/cards/${id}/status`, data);
    return response?.data?.data;
  };

  terminateVirtualCard = async (id: string, data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/cards/${id}/terminate`, data);
    return response?.data?.data;
  };

  sendBulkAction = async (data: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`admin/bulk-actions`, data);
    return response.data;
  };

  getBulkAction = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/bulk-actions`);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // Product Config
  // ************** //
  // ******************************** //

  getCurrencyMerchantCount = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/settings/merchants/currency`);
    return response.data;
  };

  getMerchantList = async (
    currency: string,
    params: {
      limit: string | number;
      page: string;
      type?: string;
      merchant_name?: string;
      payment_type: productType;
      payment_method: productType;
      status?: string[];
    }
  ) => {
    let formattedParams;
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const newType = params.type === 'all' ? undefined : params.type;
    if (params?.status) {
      formattedParams = {
        ...params,
        enabled: params.status[0] === 'enabled',
        type: newType
      };
    } else {
      formattedParams = { ...params, type: newType };
    }
    delete formattedParams.status;
    const response = await this.instance.get(`/admin/settings/merchants`, {
      params: { currency, ...formattedParams }
    });
    return response.data;
  };

  getProductConfiguration = async (
    currency: string,
    params:
      | {
          account_id?: string;
          currency?: CurrencyType;
          payment_method?: 'bank_transfer' | 'wallet' | 'card' | 'pay_with_bank' | 'bank_account' | 'mobile_money' | undefined;
          payment_type?: 'collection' | 'disbursement';
        }
      | undefined
  ) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/settings/merchants/configuration`, {
      params: { currency, ...params }
    });
    return response;
  };

  updateProductConfiguration = async (data: updateProductConfigData) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/settings/merchants/configuration`, data);
    return response;
  };

  getNotificationConfiguration = async (merchantId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/notification-configurations/${merchantId}`);
    return response.data;
  };
  // ******************************** //
  // ************** //
  // WALLET SWAP APIS (CONVERSIONS)
  // ************** //
  // ******************************** //

  getSwaps = async (page: number, limit: number, sortingParams: SortingParamsType) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/conversions`, {
      params: { page, limit, ...sortingParams }
    });
    return response?.data?.data;
  };

  getMarkUp = async (fromCurrency: string, toCurrency: string) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/conversions/markups/`, {
      params: { from_currency: fromCurrency, to_currency: toCurrency }
    });
    return response?.data;
  };

  updateMarkUp = async (data: { from_currency?: string; to_currency?: string; base_markup: string }) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`/admin/conversions/markups`, data);
    return response?.data;
  };

  exportSwaps = async (format: FileFormatType, fieldsToExport: string[], sortingParams: SortingParamsType) => {
    const token: string | null | boolean = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/conversions`, {
      params: { format, ...sortingParams, fieldsToExport },
      responseType: 'blob'
    });
    return response;
  };

  getSingleSwap = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/conversions?reference=${id}`);
    return response?.data.data;
  };

  // ******************************** //
  // ************** //
  // NOTIFICATION APIS
  // ************** //
  // ******************************** //

  getNotificationConfig = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/notifications/config`);
    return response.data;
  };

  getNotifications = async (sortingParams: INotificationSettings) => {
    const params = { ...sortingParams };
    delete params.frequency;
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/notifications`, {
      params: { ...params }
    });
    return response.data?.data;
  };

  upsertNotificationConfig = async (data: {
    config: {
      type?: any;
      read?: boolean;
      frequency?: any;
      notificationEnabled: any;
    };
  }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`admin/notifications/config`, data);
    return response.data;
  };

  markAllNotificationsAsRead = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/notifications/`);
    return response.data;
  };

  markNotificationAsRead = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/notifications/${id}`);
    return response.data;
  };

  getSubscribeEmailConfig = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/report/configuration/${id}`);
    return response?.data;
  };

  // ******************************** //
  // ************** //
  // IDENTITY APIS
  // ************** //
  // ******************************** //

  getVerifications = async (params: {
    page?: number | string;
    limit?: number | string;
    sortingParams: SortingParamsType;
    format?: FileFormatType;
    fieldsToExport?: Array<string>;
  }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const requestParams = params.format
      ? { exportFormat: params.format, ...params.sortingParams, exportFields: params.fieldsToExport }
      : { page: params.page, limit: params.limit, ...params.sortingParams };
    const responseType = params.format ? 'blob' : 'json';
    const response = await this.instance.get(`admin/identities/verifications`, {
      params: requestParams,
      responseType
    });
    if (params.format) {
      return response;
    }
    return response?.data?.data;
  };

  getVerification = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/identities/verifications/${id}`);
    return response?.data?.data;
  };

  getMerchantVerifications = async (params: {
    page?: number | string;
    limit?: number | string;
    sortingParams: SortingParamsType;
    format?: FileFormatType;
    fieldsToExport?: Array<string>;
    id: string;
  }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const requestParams = params.format
      ? { exportFormat: params.format, ...params.sortingParams, exportFields: params.fieldsToExport }
      : { page: params.page, limit: params.limit, ...params.sortingParams };
    const responseType = params.format ? 'blob' : 'json';
    const response = await this.instance.get(`admin/identities/merchants/${params.id}/verifications`, {
      params: requestParams,
      responseType
    });
    if (params.format) {
      return response;
    }
    return response?.data?.data;
  };

  addIdentityMerchant = async (data: AccessDataT) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`admin/identities/access`, data);
    return response.data;
  };

  updateIdentityMerchantAccess = async (data: AccessDataT) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/identities/merchants/${data.kora_id}/access`, { enable: data.enable });
    return response.data;
  };

  grantRegionAccess = async (data: AccessDataT) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`admin/identities/access`, data);
    return response.data;
  };

  getMerchantsWithAccess = async (params: {
    page?: number | string;
    limit?: number | string;
    sortingParams: SortingParamsType;
    format?: FileFormatType;
    fieldsToExport?: Array<string>;
  }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const requestParams = params.format
      ? { exportFormat: params.format, ...params.sortingParams, exportFields: params.fieldsToExport }
      : { page: params.page, limit: params.limit, ...params.sortingParams };
    const responseType = params.format ? 'blob' : 'json';
    const response = await this.instance.get(`admin/identities/merchants`, {
      params: requestParams,
      responseType
    });
    if (params.format) {
      return response;
    }
    return response?.data?.data;
  };

  getIdentityMerchant = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/identities/merchants/${id}`);
    return response?.data?.data;
  };

  getIdentityStatistics = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/identities/merchants/statistics`);
    return response?.data;
  };

  getIdentityBillings = async (params: {
    page?: number | string;
    limit?: number | string;
    sortingParams: SortingParamsType;
    format?: FileFormatType;
    fieldsToExport?: Array<string>;
    id: string;
  }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');

    const requestParams = params.format
      ? { exportFormat: params.format, ...params.sortingParams, exportFields: params.fieldsToExport }
      : { page: params.page, limit: params.limit, ...params.sortingParams };
    const responseType = params.format ? 'blob' : 'json';
    const response = await this.instance.get(`admin/identities/merchants/${params.id}/billings`, {
      params: requestParams,
      responseType
    });
    if (params.format) {
      return response;
    }
    return response?.data?.data;
  };

  // ******************************** //
  // ************** //
  // SPOOLING TRANSACTION REPORT APIS
  // ************** //
  // ******************************** //

  generateSpoolingTransactionReportSerivce = async (data: ReportPayLoadType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`admin/processors/reports`, data);
    return response.data;
  };

  getAllSpoolingTransactionReportsService = async (page: string, limit: string, sortingParams: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/processors/reports`, {
      params: { page, limit, ...sortingParams }
    });
    return response.data;
  };

  retryFailedSpoolingTransactionReportService = async (data: number[]) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/processors/reports`, data);
    return response.data;
  };

  // ******************************** //
  // ************** //
  // PAUSED PAYMENTS APIS
  // ************** //
  // ******************************** //

  getPausedPayments = async (page: number, limit: number, currency: string, sortingParams: {}) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/paused/payouts`, {
      params: { page, limit, currency, ...sortingParams }
    });

    return response.data?.data;
  };

  getSinglePausedPayment = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/payouts/${id}`);
    return response.data?.data;
  };

  unpausePayment = async ({ references, action }: { references: string[]; action?: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/transactions/payouts/unpause`, {
      references,
      action
    });
    return response.data;
  };

  unpauseAllPayments = async (action?: string, currency?: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.patch(`admin/transactions/payouts/unpause`, {
      action,
      currency
    });
    return response.data;
  };

  getPausedSummary = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/transactions/paused/payouts/summary`);
    return response.data?.data;
  };

  // ******************************** //
  // ************** //
  // PARTNERS BALANCE APIS
  // ************** //
  // ******************************** //

  getPartnersAccounts = async () => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/partner-funding/account`);
    return response.data;
  };

  getPartnerBalanceHistory = async (accountId: string, page: string, limit: number, sortingParams: SortingParamsType) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/partner-funding/transactions?account_id=${accountId}`, {
      params: {
        page,
        limit,
        ...sortingParams
      }
    });
    return response.data;
  };

  exportPartnersBalance = async (sortingParams: SortingParamsType, format: FileFormatType, fieldsToExport: string | string[]) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/partner-funding/transactions`, {
      params: {
        format,
        ...sortingParams,
        fieldsToExport
      },
      responseType: 'blob'
    });
    return response;
  };

  getPartnerBalance = async (accountId: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/partner-funding/${accountId}/account`);
    return response.data;
  };

  fundPartnerBalance = async (values: IPartnerFundingValues) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.post(`admin/partner-funding/initiate`, values);
    return response.data;
  };

  getPartnerBalanceDetails = async (reference: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`admin/partner-funding/${reference}/transactions`);
    return response.data;
  };

  requeryPartnerBalanceFunding = async (reference: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token || '');
    const response = await this.instance.get(`/admin/partner-funding/${reference}/requery`);
    return response.data;
  };

  updateMerchantService = async (id: string, body: any) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    await this.instance.put(`/admin/merchants/services/${id}`, body);
    return body;
  };

  // ******************************** //
  // ************** //
  // PAYMENT PREFERENCES
  // ************** //
  // ******************************** //

  getMerchantsPaymentPreferencesRequest = async (params: { page?: number; limit?: number; merchantId?: string; status?: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/settings/bank-transfer-settings-requests`, { params });
    return response.data.data;
  };

  getSingleMerchantsPaymentPreferencesRequest = async (id: string) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.get(`/admin/settings/${id}/bank-transfer-settings-requests`);
    return response.data.data;
  };

  approveOrRejectPaymentPreferenceRequest = async ({ id, status, comments }: { id: number; status: string; comments?: string }) => {
    const token = Storage.checkAuthentication();
    this.setToken(token);
    const response = await this.instance.patch(`/admin/settings/${id}/process-bank-transfer-settings-request`, {
      status,
      ...(comments && { comments })
    });
    return response.data.data;
  };
}
